<template>
  <div class="address-list">
    <header class="page-header">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span>{{ mode === 'add' ? '添加地址' : '编辑地址' }}</span>
      <span></span>
    </header>
    <main class="page-body">
      <van-form @submit="onSubmit">
        <van-field
          v-model="addressInfo.name"
          label="收件人"
          placeholder="收件人"
          :rules="[{ required: true, message: '请填写收件人' }]"
        />
        <van-field
          v-model="addressInfo.tel"
          label="电话号码"
          placeholder="电话号码"
          :rules="[{ required: true, message: '请填写电话号码' }]"
        />
        <van-field
          readonly
          clickable
          name="picker"
          :value="country"
          label="国家"
          placeholder="点击选择国家"
          @click="showPicker = true"
        />

        <van-field
          v-model="addressInfo.province"
          label="省份"
          placeholder="省份"
          :rules="[{ required: true, message: '请填写省份' }]"
        />
        <van-field
          v-model="addressInfo.city"
          label="城市"
          placeholder="城市"
          :rules="[{ required: true, message: '请填写城市' }]"
        />
        <van-field
          v-model="addressInfo.address"
          label="详细地址"
          placeholder="详细地址"
          :rules="[{ required: true, message: '请填写详细地址' }]"
        />
        <van-field
          v-model="addressInfo.postalCode"
          label="邮编"
          placeholder="邮编"
        />
        <van-field
          v-model="addressInfo.email"
          label="Email"
          placeholder="Email"
        />
        <van-field name="checkbox" label="设为默认">
          <template #input>
            <van-checkbox v-model="addressInfo.isDefault" shape="square" />
          </template>
        </van-field>
        <div class="btn-block">
          <van-button round block type="info" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
      <div class="btn-block" v-show="addressInfo.id">
        <van-button block round type="danger" @click="onDelete">
          删除
        </van-button>
      </div>
    </main>
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>
<script>
import { submitAddress, removeAddress } from '@/api/account';
import { getCountryList } from '@/api/deliver.js';
export default {
  props: {
    mode: {
      type: String,
      default: 'add',
    },
  },
  data() {
    return {
      addressInfo: {
        id: '',
        country: '',
        city: '',
        province: '',
        name: '',
        tel: '',
        address: '',
        email: '',
        postalCode: '',
        isDefault: false,
      },
      showPicker: false,
      columns: ['中国', '马来西亚', '俄罗斯', '印尼', '菲律宾', '新加坡'],
    };
  },
  created() {
    this.addressInfo = this.$route.query;
    this.country = this.columns[this.$route.query.country - 1];
    this.countryId = this.$route.query.country;
    getCountryList().then(res => {
      this.countryList = res;
      this.columns = res.map(item => item.sCnName);
    });
  },
  methods: {
    onDelete() {
      this.$dialog.confirm({ message: '确认删除吗？' }).then(() => {
        removeAddress(this.addressInfo.id).then(res => {
          this.$toast(res.Info);
          if (res.Result) {
            this.$router.go(-1);
          }
        });
      });
    },
    onConfirm(value, index) {
      this.showPicker = false;
      this.country = value;
      this.countryId = this.countryList[index].PK_ID;
    },
    onSubmit() {
      this.$toast.loading({ duration: 0 });
      submitAddress({
        id: this.addressInfo.id || undefined,
        receiver: this.addressInfo.name,
        country: this.countryId,
        province: this.addressInfo.province,
        city: this.addressInfo.city,
        address: this.addressInfo.address,
        tel: this.addressInfo.tel,
        postalCode: this.addressInfo.postalCode,
        email: this.addressInfo.email,
        isDefault: this.addressInfo.isDefault,
      })
        .then(res => {
          this.$toast.clear();
          if (res.Result) {
            this.$router.go(-1);
          } else {
            this.$toast.fail(res.Info);
          }
        })
        .catch(() => {
          this.$toast.clear();
        });
    },
    onChangeDetail(val) {
      if (val) {
        this.searchResult = [
          {
            name: '黄龙万科中心',
            address: '杭州市西湖区',
          },
        ];
      } else {
        this.searchResult = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-block {
  margin: 16px;
}
</style>
